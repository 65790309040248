<template>
<b-row>
  <b-col cols="7">
    <b-card>
      <!-- <baseform
        :fields="fields"
        :posturl="dataurl"
        :title="title"
        @showData="showData"
      /> -->
      <b-col cols="12">
        <form-input
          v-model="name"
          rules="required"
          label="Nama"
          placeholder="Nama Role"
        />
      </b-col>
      <b-row>
        <b-col cols="12">

          <b-overlay
            :show="loading"
            variant="light"
            :opacity="0.5"
            rounded="sm"
          >

            <b-table
              ref="selectable"
              selectable
              responsive
              :items="item"
              :fields="fieldsTable"
              @row-selected="onRowSelected">

              <template #head(check)>
                <b-form-checkbox
                  v-model="checkAll"
                  class="custom-control-primary"
                  name="check-button"
                  @input="selectAllRows"
                />
              </template>
              <template #cell(check)="data">
                <b-form-checkbox
                  class="custom-control-primary"
                  name="check-button"
                  :checked="data.item.check"
                  disabled
                />
              </template>
              <template #cell(name)="data">
                <span v-if="!data.item.keys.includes('.')"><b>{{data.item.name}}</b></span>
                <span v-if="data.item.keys.includes('.') && data.item.keys.split('.').length == 2">&nbsp;&nbsp;{{data.item.name}}</span>
                <span v-if="data.item.keys.includes('.')  && data.item.keys.split('.').length > 2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{data.item.name}}</span>
              </template>
            </b-table>
          </b-overlay>
        </b-col>
        
        <!-- <b-col cols="6">

          <b-overlay
            :show="loading"
            variant="light"
            :opacity="0.5"
            rounded="sm"
          >

            <b-table
              ref="selectable"
              selectable
              responsive
              :items="itemDash"
              :fields="fieldsTableDash"
              @row-selected="onRowSelected">

              <template #head(check)>
                <b-form-checkbox
                  v-model="checkAll"
                  class="custom-control-primary"
                  name="check-button"
                  @input="selectAllRows"
                />
              </template>
              <template #cell(check)="data">
                <b-form-checkbox
                  class="custom-control-primary"
                  name="check-button"
                  :checked="data.item.check"
                  disabled
                />
              </template>
              <template #cell(name)="data">
                <span v-if="!data.item.keys.includes('.')"><b>{{data.item.name}}</b></span>
                <span v-if="data.item.keys.includes('.') && data.item.keys.split('.').length == 2">&nbsp;&nbsp;{{data.item.name}}</span>
                <span v-if="data.item.keys.includes('.')  && data.item.keys.split('.').length > 2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{data.item.name}}</span>
              </template>
            </b-table>
          </b-overlay>
        </b-col> -->
      </b-row>
      <b-form-group
        label-cols-md="4"
        label=""
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          type="button"
          @click.prevent="submitForm"
        >
          <span v-if="loading">
            <b-spinner small />
            Loading...
          </span>
          <span v-else>Simpan</span>
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="$router.go(-1)"
        >
          <span>Batal</span>
        </b-button>
      </b-form-group>
    </b-card>
  </b-col>
</b-row>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard, BOverlay, BTable, BFormGroup, BFormCheckbox, BSpinner, BButton, BRow, BCol } from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import config from './Config.js'

export default {
  components: {
    baseform,
    BCard,
    BOverlay,
    BTable,
    BFormGroup, 
    BFormCheckbox, 
    BSpinner,
    FormInput,
    BButton,
    BRow, 
    BCol
  },
  data() {
    return {   
      title:config.title,
      dataurl:config.dataulr,
      baseroute:config.baseroute,
      fields: [
        { key: 'name', label: 'Nama', type: 'input', rules:'required' },
      ],
      item:[],
      itemDash:[],
      fieldsTable:[
        { key: 'check', label: ''},
        { key: 'name', label: 'Menu'},
      ],
      fieldsTableDash:[
        { key: 'check', label: ''},
        { key: 'name', label: 'Dashboard'},
      ],
      loading:false,
      checkAll:false,
      selected:null,
      model:null,
      menu:[],
      name:""
    }
  },
  mounted(){
    if(this.$route.params.id){
      this.fetchData()
    }else{
      this.getMenu()
    }
  },
  methods:{
    async submitForm(){
      if(this.name == "") return this.$bvToast
        .toast('Please correct form input Name', {
          title: 'Role Name Error',
          solid: true,
          variant:'danger'
        })
      
      this.loading = true

      var params = {
        name : this.name
      }

      var http
      if(this.$route.params.id){
        http = this.$http.put(this.dataurl+"/"+this.$route.params.id, params)
      }else{
        http = this.$http.post(this.dataurl, params)
      }
      
      let {data} = await http
      
      if(data)
        this.submitPermission(data.data.id)
    },
    submitPermission(role_id){
      var key = []
      for (let i = 0; i < this.item.length; i++) {
        if(this.item[i].check){
          key.push(this.item[i].keys)
        }
      }
      
      var params = {
        "keys": key
      }

      this.$http.put(this.dataurl+"/"+role_id+"/permission", params).then(() =>{
        this.successSubmit()
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
      .finally(() => {this.loading = false})
    },
    successSubmit(){
      this.loading = false
      this.$bvToast
        .toast('', {
          title: 'Success',
          solid: true,
          variant:'success'
        })
      this.$router.go(-1)
    },
    errorSubmit(err){
      const msg = err.response.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    fetchData(){
      this.loading = true
      this.$http.get(this.dataurl+"/"+this.$route.params.id).then(res => {
        var data = res.data
        this.name = data.name
        this.menu = data.permissions
        this.getMenu()
      })
    },
    async getMenu(){
      await this.$http.get('/permission').then(res => {
        this.loading = false
        this.model = res.data.data
        var arr = []
        var arrDash = []
        for (let i = 0; i < this.model.length; i++) {
          var check = this.menu.find(x => x == this.model[i].key)?true:false
          // // if(this.model[i]['type'] == 'dashboard')
          //   arrDash.push({
          //     index: i,
          //     keys: this.model[i].key,
          //     name: this.model[i].name,
          //     check: check
          //   })
          // else
            arr.push({
              index: i,
              keys: this.model[i].key,
              name: this.model[i].name,
              check: check
            })
        }
        this.item = arr
        this.itemDash = arrDash
      })

      for(let i = 0; i < this.item.length; i++){
        if(this.item[i].check == true)
          this.$refs.selectable.selectRow(i)
      }
    },
    // showData(data){
    //   this.menu = data.permissions
    //   this.getMenu()
    //   this.loading = true
    // },
    onRowSelected(items) {
      this.selected = items
      for (let i = 0; i < this.item.length; i++) {
        this.item[i].check = false
      }
      for(let j=0; j < items.length; j++){
        this.item[items[j].index].check = true
      }
    },
    selectAllRows() {
      if(this.checkAll){
        this.$refs.selectable.selectAllRows()
      }else{
        this.$refs.selectable.clearSelected()
      }
    }
  }
}
</script>